<template>
  <v-sheet flat :style="{display: 'flex', flexDirection: 'column', width: '100vw'}">
    <v-row>
      <v-col v-if="step == 0" style="height: 100vh;" cols="12" md="12" class="d-flex justify-center">
        <v-card :style="{display: 'flex', flexDirection: 'column', height: '100%', 'justify-content': 'center'}" flat width="600" style="align-self: center; padding: 10px; justify-content: center;">
          <div style="display: flex; justify-content: center; margin-bottom: 50px;">
            <v-img v-if="!$vuetify.theme.dark" class="align-center" max-width="400" height="100" src="@/assets/tiimi_logo_bonw.png"/>
            <v-img v-else class="align-center" max-width="400" height="auto" src="@/assets/svg/darklogo.svg"/>
          </div>
          <v-card-title class="mb-2">
            {{$t('loginflow.sign_in_to_tiimi')}}!
          </v-card-title>
          <v-form 
            :lazy-validation="true"
            ref="form"
            @submit="submit"
          >
            <v-row>
              <v-col
                cols="8"
              >
                <v-text-field
                  type="text"
                  solo
                  rounded
                  v-model="form.full_name"
                  placeholder="John Doe"
                  :label="$t('full_name')"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
              >
                <v-select @change="changeLanguage($event)" v-model="form.language" :items="[{ value: 'en', text: 'English' }, { value: 'fi', text: 'Finnish' }]" rounded solo label="Lang">
                </v-select>
              </v-col>
            </v-row>
            <v-text-field
              autocomplete="username"
              type="email"
              solo
              rounded
              v-model="form.email"
              :label="$t('email')"
              :rules="emailRules"
            >
            </v-text-field>
            <v-text-field
              autocomplete="new-password"
              :label="$t('password')"
              solo
              rounded
              type="password"
              placeholder="********"
              v-model="form.password"
              :rules="passRules"
            ></v-text-field>
            <v-text-field
              :label="$t('password_again')"
              autocomplete="new-password"
              solo
              rounded
              type="password"
              placeholder="**********"
              v-model="form.password_again"
              :rules="required"
            ></v-text-field>
            <span class="mr-1">{{$t('i_agree')}}</span><a @click.stop target="_blank" href="https://app.websitepolicies.com/policies/view/6x9c7mr7">{{$t('loginflow.terms')}}</a>
            <v-card-actions style="justify-content: center;">
              <v-btn large rounded class="mt-5" :loading="loading" type="submit" color="primary">{{$t('signin')}}</v-btn>
            </v-card-actions>
            <div style="text-align: center;">
              <v-btn x-small text @click="$router.push({path: '/login', query: $route.query})">{{$t('loginflow.login_here')}}</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col v-else style="height: 100vh; flex-direction: column;" cols="12" md="12" class="d-flex justify-center align-center">
        <v-sheet>
        <v-progress-linear
          color="light-blue darken-1"
          height="20"
          value="66"
          rounded
          class="mx-3 mb-10"
          striped
        >
          <div class="white--text">
            2 / 3
          </div>
        </v-progress-linear>
          <create :flat="true"></create>
        </v-sheet>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-sheet style="background-color: #2196F3; height: 100vh; text-align: center; width: 100%; display: flex; justify-content: center; align-content: center; flex-direction: column;">
          <div class="d-flex justify-center">
            <v-img src="@/assets/svg/signin.svg" contain height="250" width="300"></v-img>
          </div>
          <v-card flat style="justify-content: center; background-color: #2196F3; color: white;">
            <v-card-title class="text-h4 font-weight-bold mt-5" style="justify-content: center;">
              {{ $t('hit_a_home_run') }}!
            </v-card-title>
            <v-card-text class="text-h6 mt-6 white--text">
              • {{ $t('all_in_one_place') }}
              <br>
              • {{ $t('effortles_tagging') }}
              <br>
              • {{ $t('improve_play') }}
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
import Create from '../components/team/Create.vue'
/*
  Possible query params
  plan=plan short name
  lang=language "en" or "fi"
*/
export default {
  components: { Create },
  created() {
    this.track('Opened sign in')

    let lang = this.$route.query?.lang
    let prev_lang = window.localStorage.getItem('tiimi-lang')
    if(lang && (lang == 'en' || lang == 'fi') && lang != prev_lang ) {
      window.localStorage.setItem('tiimi-lang', lang)
      window.location.reload()
    }

    if(lang == 'en' || lang == 'fi') {
      this.form.language = lang
    }
  },
  data: () => ({
    step: 0,
    form: {
      email: '',
      password: '',
      password_again: '',
      language: 'en',
      full_name: ''
    },
    agree_to_terms: false,
    agree_to_terms_error: false,
    languages: [
      { value: 'en', text: 'EN' },
      { value: 'fi', text: 'FI' },
    ],
    loading: false,
    passRules: [
      v => !!v || 'Required',
      v => (v && v.length >= 8) || 'Min 8 characters',
    ],
    required: [
      v => !!v || 'Required',
    ],
    emailRules: [
      v => !!v || 'Required',
      v => /.+@.+\..+/.test(v) || 'Not valid email',
    ],
  }),
  methods: {
    ...mapActions({
      signin: 'auth/signin',
      showSnackbar: 'noti/showSnackbar',
      error: 'noti/error',
    }),
    ...mapActions('user', [
      'initUser'
    ]),
    changeLanguage(e) {
      this.$i18n.locale = e
      window.localStorage.setItem('tiimi-lang', e)
    },
    submit(e) {
      e.preventDefault();
      
      this.loading = true
      this.form.language = this.form.language || 'en'
      let val = this.$refs.form.validate()

      if(!val) {
        if(!this.agree_to_terms) this.agree_to_terms_error = true
        this.loading = false
        return
      }

      this.signin(this.form)
        .then(res => {
          console.log(res)
          this.initUser(res.data.token)

          if(this.$route.query.invite_code) {
            this.$router.push({ path: '/team/join', query: { invite_code: this.$route.query.invite_code } })
          } else {
            this.step = 1
          }

          // this.showSnackbar({ text: this.$t('loginflow.signin_successfull'), duration: 12 })

          this.track('Signed in')
        })
        .catch(err => {
          this.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    inbound() {
      return !!this.$route.query?.plan
    },
    stepperStep() {
      return 1
    }
  }
}
</script>