<template>
  <v-container fluid>
    <add-ticks :popout="true"></add-ticks>
  </v-container>
</template>

<script>
import AddTicks from '@/components/ticks/AddTicks.vue'

export default {
  components: { AddTicks },
  created() {

  },
  beforeDestroy() {
    
  }
}
</script>