<template>
  <v-card :flat="embedded" class="pb-4" :loading="loading">
    <v-card-title v-if="!embedded">
      {{$t('join.join_a_team')}}
    </v-card-title>
    <v-card-subtitle v-if="!embedded">
      {{ $t('join.use_code') }}
    </v-card-subtitle>
    <v-card-text v-if="!invite_code">
      <v-otp-input
        :key="inputKey"
        :class="{'mt-10': !embedded}"
        length="6"
        @finish="join({ join_code: $event })"
        :loading="loading"
        autofocus
      >
      </v-otp-input>
    </v-card-text>
    <v-card-text v-else>
      <v-card-title>
        {{$t('join.joining_team')}}
      </v-card-title>
    </v-card-text>
      <!-- <v-btn 
        class="float-right mt-8 mr-5" 
        color="primary"
        @click="join()"
      >
        join!
      </v-btn> -->
      <v-btn
        v-if="!embedded"
        @click="$router.push('/team/options')"
        class="mt-8 ml-5"
      >
        back
      </v-btn>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['embedded'],
  data: () => ({
    loading: false,
    inputKey: 0,
    invite_code: null
  }),
  created() {
    if(this.$route.query.invite_code) {
      this.invite_code = this.$route.query.invite_code
      this.joinTeamWithInviteCode(this.$route.query.invite_code)
    }
  },
  methods: {
    ...mapActions('user', [
      'initUser',
      'joinTeam'
    ]),
    ...mapActions('noti', [
      'showSnackbar'
    ]),
    joinTeamWithInviteCode(code) {
      this.loading = true
      this.join({ invite_code: code })
    },
    join(code) {
      this.loading = true
      this.joinTeam(code)
        .then(res => {
          this.initUser(res.token)

          this.$nextTick(() => {
            this.$router.push('/videos')
          })
          
          this.showSnackbar({
            text: 'Success! Welcome to your new team.',
            type: 'success',
            duration: 7
          })
        })
        .catch(err => {
          this.showSnackbar({
            text: err.response.data,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
          this.inputKey++
        })
    }
  }
}
</script>